.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    margin: 5rem 5rem 5rem 5rem;
    background-color: #f0f0f0;
    min-height: 80vh;
    width: 100%;
    box-sizing: border-box;
  }
  
  .contact-title {
    text-transform: uppercase;
    margin-bottom: 1rem;
    background: -webkit-linear-gradient(#ff7e5f, #feb47b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: fadeIn 1s ease-in-out;
  }
  
  .contact-text {
    margin-bottom: 1rem;
    text-align: center;
    color: #333;
    font-size: 1.2rem;
    line-height: 2 !important;
  }
  
  .contact-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    margin-bottom: 2rem;
  }
  
  .contact-form-left, .contact-form-right {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 380px;
    margin-bottom: 1rem;
  }
  
  .contact-input {
    margin-bottom: 1rem;
  }
  
  .send-button {
    align-self: flex-end;
    background: linear-gradient(45deg, #ff7e5f, #feb47b);
    color: #fff;
    padding: 0.5rem 1.5rem;
    margin-top: 1rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .send-button:hover {
    transform: scale(1.05);
  }
  
  .social-media {
    display: flex;
    justify-content: center;
  }
  
  .social-icon {
    font-size: 2rem;
    margin: 0 1rem;
    color: #333;
    transition: transform 0.5s ease-in-out, color 0.5s ease-in-out;
  }
  
  .social-icon:hover {
    color: #ff7e5f;
    animation: spin 0.5s linear;
  }
  
  @media (max-width: 600px) {
    .contact-form {
      flex-direction: column;
      align-items: center;
    }
  
    .contact-form-left, .contact-form-right {
      width: 100%;
    }
  
    .contact-title {
      font-size: 1.5rem;
    }
  
    .contact-text {
      font-size: 1rem;
    }
  
    .social-icon {
      font-size: 1.5rem;
    }
  }
  