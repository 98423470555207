@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.name-class {
  text-transform: uppercase;
  background: url('../../assets/images/Background1.png') no-repeat center center;
  background-size: auto auto;
  background-clip: border-box;
  background-size: 100% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  line-height: 1.5 !important;
}

[data-theme="dark"] .name-class {
  background: url('../../assets/images/Background2.png') no-repeat center center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  line-height: 1.5 !important;
}

.intro-title{
  text-align: center;
  color: lightcoral;
  line-height: 2.5 !important;
}

[data-theme="dark"] .intro-title{
  text-align: center;
  color: white;
  line-height: 2.5 !important;
}

.intro-desc {
  line-height: 1.5 !important;
}

.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0 2rem;
  padding-top: 70px;
  background-image: linear-gradient(45deg, #ddd6f3 30%, #faaca8 90%);
  color: #000;
  transition: background 0.3s ease;
}

.welcome-container[data-theme="dark"] {
  background-image: linear-gradient(45deg, #141e30 30%, #243b55 90%);
  color: #fff;
}

.welcome-text-container {
  text-align: center;
  order: 2;
}

.welcome-image-container {
  order: 1;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.welcome-image {
  width: 250px;
  border-radius: 50%;
  transition: width 0.3s ease;
}

.social-icons {
  display: flex;
  justify-content: center;
  order: 4;
}

.social-icons .MuiIconButton-root {
  font-size: 1rem;
  transition: transform 0.3s ease-in-out;
}

.social-icons .MuiIconButton-root:hover {
  animation: spin 0.5s linear;
}

.welcome-text-container{
  width: 300px;
}


@media (min-width: 600px) {
  .welcome-container {
    flex-direction: row;
    padding-top: 80px; 
  }

  .welcome-text-container {
    text-align: left;
    order: 0; 
  }

  .welcome-image-container {
    order: 0; 
    margin-bottom: 0;
  }

  .welcome-image {
    width: 300px; 
  }

  .social-icons {
    order: 0; 
  }

  .intro-title{
    text-align: left;
    line-height: 3.5 !important;
  }
  
  [data-theme="dark"] .intro-title{
    text-align: left;
    line-height: 3.5 !important;
  }
  
  .intro-desc {
    line-height: 2.5 !important;
  }

  .welcome-text-container{
    width: 450px;
  }


}

@media (min-width: 960px) {
  .welcome-container {
    padding-top: 100px;
  }

  .welcome-text-container{
    width: 700px;
  }

  .welcome-image {
    width: 450px;
  }

  .intro-title{
    text-align: left;
    line-height: 3.5 !important;
  }
  
  [data-theme="dark"] .intro-title{
    text-align: left;
    line-height: 3.5 !important;
  }
  
  .intro-desc {
    line-height: 2.5 !important;
  }
}
