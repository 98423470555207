html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: 'Merienda', cursive;
}

body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  background: url('./assets/images/SKM-transperant.png') no-repeat center center;
  background-size: 60%;
  background-attachment: fixed;
}

.app-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem; /* Add padding to the left and right */
  font-family: 'Merienda', cursive;
}

.main-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 10%;
}
