@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.transparent-header {
  background-color: transparent !important;
  box-shadow: none !important;
  transition: top 0.3s ease-in-out, padding 0.3s ease-in-out;
  padding: 10px 20px;
  z-index: 1000;
}

[data-theme="dark"] .transparent-header {
  background-color: transparent !important;
}

.header-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 20px; /* Margin for all screens */
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-image {
  width: 50px;
  height: 50px;
  margin-right: 8px;
  border-radius: 50%;
  transform: scale(1.1);
  transition: transform 0.5s ease-in-out;
}

.logo-link:hover .logo-image {
  animation: spin 0.5s linear;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.menu-item {
  animation: bounce 2s infinite;
}

.menu-item:hover {
  color: #ff7e5f;
  animation-play-state: paused;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .header-toolbar {
    padding: 5px 10px;
  }

  .logo-image {
    width: 40px;
    height: 40px;
  }

  .menu-item {
    display: none;
  }

  .MuiIconButton-root {
    display: block;
  }

  .MuiMenuItem-root {
    width: 100%;
    text-align: center;
  }

  .MuiMenuItem-root:hover {
    background-color: #f0f0f0;
  }
}

